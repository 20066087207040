@import url("https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* main */

:root {
  --primary_font_color: #150115;
  --secondary_font_color: #706666;
  --accent_color: #b030b0;

  --art_gallery_background_color: #f3ecec;
  --art_gallery_wireframe_border_color: #535446;

  --notex_primary_color: #5800ff;
  --notex_secondary_color: #e0719e;

  --pet_nanny_background_color: #8fdbff;
  --pet_nanny_border_color: #eff5ff;
  --pet_nanny_nicole_border_color: #3a86ff;
  --pet_nanny_nicole_secondary_color: #838383;

  --captured_primary_font_color: #24272d;
  --captured_secondary_font_color: #a8adad;

  --max_v3: calc(max(3vh, 3vw));
  --max_v5: calc(max(5vh, 5vw));

  --min_v1: calc(min(1vh, 1vw));
  --min_v2: calc(min(2vh, 2vw));
  --min_v3: calc(min(3vh, 3vw));
  --min_v4: calc(min(4vh, 4vw));

  font-family: "Lato", sans-serif;
  font-size: 20px;
  color: var(--primary_font_color);
  text-align: center;
}

body {
  margin: 0;
}

main {
  margin: auto 10%;
}

section {
  margin: 15vh auto 0 auto;
}

h1 {
  margin: 0;
  font-size: 2.5rem;
}

h2 {
  font-size: 1.6rem;
  margin: 10vh auto 0 auto;
}

h3 {
  font-size: 1.2rem;
}

p {
  margin: 1.2vh auto 0 auto;
  font-size: 1rem;
  max-width: 560px;
  color: var(--secondary_font_color);
  word-spacing: 3px;
}

q {
  font-size: 1.1rem;
}

h1 + p {
  margin-top: 5vh;
}

h2 + p {
  margin-top: calc(max(3vh, 30px));
}

p + p {
  margin-top: 1.5rem;
}

a {
  text-decoration: none;
  color: var(--primary_font_color);
}

li {
  font-size: 1rem;
}

img,
video {
  display: block;
  box-sizing: border-box;
  max-width: calc(min(100%, 2000px));
  margin: 5vh auto;
}

a:hover {
  color: var(--accent_color);
}

p.max_width_720px {
  max-width: 720px;
}

.w2k {
  max-width: 2000px;
}

.w100p {
  width: 100%;
}

.margin_top_regular {
  margin-top: 4vh;
}

.border_radius_medium {
  border-radius: var(--min_v1);
}

.border_radius_large {
  border-radius: var(--min_v2);
}

.thin_light_border {
  box-sizing: border-box;
  border: 1px solid gainsboro;
}

.mobile_cutout {
  max-height: 75vh;
}

.mh60 {
  max-height: 60vh;
}

.mh75 {
  max-height: 75vh;
}

.mh85 {
  max-height: 85vh;
}

.mw75 {
  max-width: 75vw;
}

.mw100p {
  max-width: 100%;
}

.general_information > p {
  margin-top: 32px;
}

.general_information > p + p {
  margin-top: 8px;
}

.disclaimer > p {
  font-size: 16px;
  font-style: italic;
  margin: 5px auto;
  word-spacing: 2px;
}

/* header */

#header_menu {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: top 250ms, background-color 250ms, height 250ms;
}

#header_menu.hide {
  top: -100px;
}

#header_menu.full {
  height: 100%;
  background-color: var(--accent_color);
  backdrop-filter: unset;
}

body.disable_scroll {
  overflow: hidden;
}

#header_menu > nav {
  width: 80%;
  max-width: 2000px;
  display: flex;
  margin: auto;
  padding: 24px;
}

#andrea_logo {
  font-family: "Leckerli One", cursive;
  font-size: 32px;
  color: var(--accent_color);
  margin: auto auto auto 0;
  transition: color 500ms;
}

#andrea_logo.white {
  color: white;
}

#toggle_menu {
  display: none;
}

#toggle_menu_checkbox {
  display: none;
}

#header_menu_links {
  display: inline-block;
  margin: auto 0;
}

#header_menu_links > a {
  font-size: 22px;
  font-weight: bold;
  padding: 8px;
  margin: auto 16px;
}

#burger_wrapper {
  display: inline-block;
  height: 27px;
  margin: 8px 0 0 0;
}

#burger {
  position: relative;
  top: 11px;
  transition: background 10ms 250ms;
}

#burger,
#burger::after,
#burger::before {
  background-color: var(--primary_font_color);
  width: 35px;
  height: 5px;
}

#burger::before,
#burger::after {
  position: absolute;
  content: '';
  left: 0;
  transition: top 200ms 250ms, transform 200ms 50ms, background-color 50ms 250ms;
}

#burger::before {
  top: -11px;
}

#burger::after {
  top: 11px;
}

#toggle_menu_checkbox:checked ~ #burger_wrapper > div {
  background: transparent;
  transition: background 10ms 250ms;
}

#toggle_menu_checkbox:checked ~ #burger_wrapper > div::before,
#toggle_menu_checkbox:checked ~ #burger_wrapper > div::after {
  transition: top 200ms 50ms, transform 200ms 250ms, background-color 50ms 250ms;
  top: 0;
  background-color: white;
}

#toggle_menu_checkbox:checked ~ #burger_wrapper > div::before {
  transform: rotate(45deg);
}

#toggle_menu_checkbox:checked ~ #burger_wrapper > div::after {
  transform: rotate(-45deg);
}

/* home page */

#hi {
  margin-top: 20vh;
  height: 75vh;
}

#hi > p {
  color: var(--primary_font_color);
}

#jump_to_projects_button.hide {
  display: none;
}

.circle_jump_button {
  display: block;
  background-color: var(--accent_color);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: auto;
}

.circle_jump_button > svg {
  transform: scale(1.3);
  position: relative;
  top: 6px;
}

.circle_jump_button {
  margin-top: 10vh;
}

.resp_01 {
  display: flex;
  margin: 5vh auto;
  width: fit-content;
  max-width: calc(min(100%, 2000px));
  align-items: flex-start;
  justify-content: center;
  gap: var(--max_v3);
}

.resp_01:has(> :nth-child(2):last-child) {
  gap: var(--max_v5);
}

.resp_01:has(> :nth-child(3):last-child) {
  gap: var(--max_v3);
}

.resp_01:has(> :nth-child(4):last-child) {
  gap: calc(max(1.5vh, 1.5vw));
}

.resp_01 img,
.resp_01 video {
  max-width: 100%;
  width: auto;
  overflow: hidden;
  margin: 0 auto;
}

.resp_01 > div {
  width: 100%;
}

.resp_equal {
  flex: 1 1 fit-content;
  margin: 0;
}

.slide_show {
  position: relative;
  bottom: 0;
  overflow-x: hidden;
  width: fit-content;
  margin: 5vh auto;
}

.slide_show > :first-child {
  visibility: hidden;
  margin: 0;
}

.slide_show > div {
  margin: 0;
  position: absolute;
  visibility: visible;
  top: 0;
  left: 0;
  right: 0;
}

.slide_show > div > * {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  margin: 0;
}

.slide_show_active_left {
  animation: slide_show_left 1s ease-out;
}

.slide_show_active_right {
  visibility: visible !important;
  animation: slide_show_right 1s ease-out;
}

@keyframes slide_show_left {
  0% {
    transform: translateX(-50%);
    visibility: visible;
  }

  100% {
    transform: translateX(-170%);
  }
}

@keyframes slide_show_right {
  0% {
    transform: translateX(70%);
    visibility: visible;
  }

  100% {
    transform: translateX(-50%);
  }
}

/* footer */

#footer_circle_jump_button {
  margin: 0;
  transform: scale(0.75);
  position: fixed;
  bottom: 30px;
  right: 30px;
  visibility: hidden;
  opacity: 0.4;
  transition: opacity 0.25s;
  z-index: 10;
}

#footer_circle_jump_button > svg {
  top: 0;
}

#footer_circle_jump_button:hover,
#footer_circle_jump_button:active {
  opacity: 1;
}

/* art gallery */

#art_gallery_hall {
  height: 329px;
  margin-top: 6vh;
  object-fit: cover;
}

.art_gallery_pain_points > div {
  max-width: 560px;
}

.art_gallery_pain_points > div > h2 {
  margin-top: 5px;
}

.art_gallery_pain_points div > ul {
  text-align: left;
  color: var(--secondary_font_color);
}

.art_gallery_pain_points > div > ul > li {
  margin-top: 20px;
}

#mia {
  margin: 5vh auto;
  font-family: 'Libre Franklin', sans-serif;
}

#mia > img {
  max-height: calc(min(700px, 35vh));
}

#mia h2 {
  font-size: 2rem;
}

#mia > div {
  text-align: left;
  width: 100%;
  max-width: 900px;
}

#mia > div > h2 {
  margin-top: 0;
}

#mia > div > h3 {
  margin: 20px auto;
}

#mia > div > ul {
  color: var(--secondary_font_color);
}

#mia > div > ul > li {
  margin: 16px auto;
}

#mia > div > p {
  margin: 50px auto;
  font-weight: bold;
  text-align: center;
  max-width: 100%;
}

div#mia > div > p > span {
  margin: 0 12px;
  display: inline-block;
  color: var(--primary_font_color);
}

.art_gallery_wireframe_border {
  border: 2px solid var(--art_gallery_wireframe_border_color);
}

#art_gallery_takeaways > div {
  gap: calc(max(12vh, 12vw));
}

#art_gallery_takeaways h2 {
  margin-top: 0;
}

.art_gallery_padding {
  padding: var(--max_v3);
  background-color: var(--art_gallery_background_color);
  box-sizing: border-box;
}

#art_gallery_fade > div {
  position: relative;
  box-sizing: border-box;
}

#art_gallery_fade > div > img:last-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: art_gallery_fade 1.8s linear infinite alternate;
  height: calc(100% - 2 * var(--max_v3));
  margin: 0;
}

@keyframes art_gallery_fade {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#art_gallery_virtual_tours_sunset {
  width: fit-content;
  max-width: 2000px;
  box-sizing: border-box;
}

#art_gallery_artworks > img {
  flex: 5 4 0;
}

#art_gallery_artworks > div {
  flex: 4 5 0;
}

#art_gallery_artworks > div > img {
  margin-top: 70%;
}

#art_gallery_artworks > div:nth-child(3) > img:first-child {
  margin-top: 20%;
}

.art_gallery_small_img {
  max-height: 40vh;
}

/* notex */

#notex_ui_mockups > h2 {
  margin-top: 10vh;
}

#julia {
  gap: var(--max_v3);
}

#julia > img {
  display: inline-block;
  max-width: 650px;
  max-height: 50vh;
  margin: 10px 0 auto auto;
  border: 8px solid black;
}

#julia > div {
  display: inline-block;
  max-width: 850px;
  vertical-align: top;
  text-align: left;
}

#julia > div > h2 {
  font-size: 2rem;
  margin-top: 0;
}

#julia > div h3 {
  margin: 24px auto 12px auto;
}

#julia > div > p {
  color: var(--primary_font_color);
  text-align: left;
  margin: 16px auto;
  max-width: 100%;
}

#julia > div > hr {
  margin: 40px auto;
  border: 4px solid var(--notex_primary_color);
}

#julia ul {
  margin-top: 0;
}

#julia li {
  margin-top: 6px;
  font-weight: 600;
}

#julia > div > div > h3 {
  color: var(--notex_secondary_color);
}

#julia > div > div + div > h3 {
  color: var(--notex_primary_color);
}

#julia q {
  display: block;
  margin: 50px auto;
  font-size: 1.2rem;
  font-weight: 600;
}

.julias_storyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
}

.julias_storyboard > img {
  display: inline-block;
  margin: 0;
  max-height: 40vh;
}

/* PetNanny */

#pet_nanny_design_steps {
  gap: var(--max_v3);
}

#pet_nanny_design_steps > div:nth-child(even) {
  margin-top: var(--max_v3);
}

#pet_nanny_design_steps > div {
  display: inline-block;
  width: fit-content;
}

#pet_nanny_design_steps > div > h2 {
  margin-top: var(--min_v2);
}

#pet_nanny_factors {
  margin: 5vh auto 0 auto;
}

#pet_nanny_factors > div {
  margin: 2vh auto;
  display: inline-block;
  flex: 1 1 30%;
  max-width: 550px;
}

#pet_nanny_factors > div > h2 {
  margin-top: 2.5rem;
}

#pet_nanny_factors > div > svg {
  transform: scale(3.2);
}

#pet_nanny_factors > div > ul {
  text-align: left;
  font-size: 0.75rem;
  color: var(--secondary_font_color);
}

#pet_nanny_factors > div > ul > li {
  margin-top: 0.75rem;
}

#nicole_persona {
  margin: var(--max_v3) auto;
  font-family: 'Cabin', sans-serif;
  letter-spacing: 0.04pc;
  width: fit-content;
}

#nicole_persona > div {
  gap: var(--max_v3);
  margin-bottom: 1rem;
}

#nicole_persona > div > div {
  display: inline-block;
  text-align: left;
  flex: 3 3 0;
}

#nicole_persona > div > div > h2 {
  margin-top: 0;
  color: var(--pet_nanny_nicole_secondary_color);
}

#nicole_persona > div > div > h2:first-of-type {
  color: initial;
}

#nicole_persona > div > div > p {
  margin: 1.5vh auto 0 0;
  color: var(--primary_font_color);
}

#nicole_persona > div > div > q {
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  max-width: 650px;
  margin: 2rem auto;
}

#nicole_persona > div > div > p > span {
  font-weight: bold;
  color: var(--pet_nanny_nicole_secondary_color);
}

#nicole_persona > div > div > ul,
#nicole_persona > div > div > ul > li {
  margin-top: 0.5rem;
  max-width: 450px;
}

#nicole_persona > div > img {
  display: inline-block;
  margin: var(--min_v1) auto;
  flex: 2 2 0;
  max-height: 55vh;
}

#nicole_persona hr {
  height: 14px;
  background-color: var(--pet_nanny_nicole_border_color);
  border: 0;
  border-radius: 4px;
  margin: 0 auto;
}

#pet_nanny_wireframes {
  flex-wrap: wrap;
  column-gap: var(--max_v3);
}

#pet_nanny_wireframes > img {
  max-height: 65vh;
  margin: 0;
}

#pet_nanny_quick_and_easy_booking img {
  max-width: 20vw;
}

.pet_nanny_padding {
  background-color: var(--pet_nanny_background_color);
  box-sizing: border-box;
  padding: 5vh;
  gap: 5vh !important;
}

#pet_nanny_safe_hands {
  gap: calc(max(1.5vh, 1.5vw)) !important;
}

.pet_nanny_padding > img,
.pet_nanny_padding > video {
  margin: 0 auto !important;
}

.pet_nanny_border {
  border: 2px solid var(--pet_nanny_border_color);
}

#pet_nanny_booking_video {
  max-width: fit-content;
  margin: 5vh auto;
}

.pet_nanny_notification_margin > div img + img {
  margin-top: 3vh;
}

#pet_nanny_usability {
  gap: 5vw;
}

#pet_nanny_question {
  margin: 75px auto;
}

#pet_nanny_question > p:nth-child(2) {
  color: var(--primary_font_color);
  font-size: 1.1rem;
  font-style: italic;
}

#pet_nanny_competitors > div {
  max-width: 40%;
  align-self: center;
}

#pet_nanny_competitors > div > ul {
  text-align: left;
  color: var(--secondary_font_color);
}

#pet_nanny_competitors > div > ul > li {
  margin-top: 0.8rem;
}

/* Mango */

#mango_note {
  margin-top: 2rem;
  font-size: 16px;
}

#mango_quote {
  font-style: italic;
  margin-top: 2rem;
}

#mango_colors_and_typography {
  position: relative;
  margin: 5vh auto 0 auto;
  max-width: calc(min(100%, 2000px));
}

#mango_colors_and_typography > div:first-child {
  display: inline-block;
  vertical-align: top;
  width: 70%;
}

#mango_colors_and_typography > img {
  max-width: 30%;
  display: inline-block;
  margin: 0;
}

#mango_colors_and_typography_italiana {
  font-family: 'Italiana', serif;
  font-size: calc(7vh + 7vw);
  text-align: right;
  margin-right: 2vw;
}

#mango_colors_and_typography_italiana > span:first-child {
  font-size: calc(2.5vh + 2vw);
  position: relative;
  bottom: 2vh;
  margin-right: 1vw;
}

#mango_colors_and_typography_libre_franklin {
  font-family: 'Libre Franklin', sans-serif;
  font-size: calc(3vh + 6vw);
  text-align: left;
}

#mango_colors_and_typography_libre_franklin > span:nth-child(2) {
  font-size: calc(1.2vh + 1.7vw);
  position: relative;
  bottom: calc(1vh + 0.5vw);
  left: 1vw;
}

#mango_colors_and_typography_libre_franklin > div {
  display: inline-block;
}

#mango_colors_and_typography_libre_franklin > div > p {
  color: var(--primary_font_color);
  position: relative;
  left: 3vw;
  font-size: calc(0.8vh + 0.7vw);
  margin: 1vh 0;
}

#mango_colors_and_typography_libre_franklin > div > p:nth-child(1) {
  font-weight: 100;
}

#mango_colors_and_typography_libre_franklin > div > p:nth-child(2) {
  font-weight: 400;
}

#mango_colors_and_typography_libre_franklin > div > p:nth-child(3) {
  font-weight: 700;
}

#mango_color_purses {
  width: 100%;
  position: absolute;
  bottom: 5%;
  display: flex;
  justify-content: space-evenly;
}

.mango_color_purse {
  display: inline-block;
  position: relative;
}

.mango_color_purse > svg {
  --mango_color_purse_size: calc(0px + 4vh + 6vw);
  height: var(--mango_color_purse_size);
  width: var(--mango_color_purse_size);
  --mango_color_purse_max_size: 250px;
  max-height: var(--mango_color_purse_max_size);
  max-width: var(--mango_color_purse_max_size);
}

.mango_color_purse > p {
  font-size: calc(0.8vh + 0.8vw);
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
}

#mango_paint_points section {
  margin-top: 0;
}

#mango_responsiveness > div > div > h2 {
  margin-top: 3vh;
}

#mango_filter_redesign > img {
  max-width: 75vw;
}

#mango_filter_redesign > img:first-child {
  flex: 2 3 auto;
}

#mango_filter_redesign > img:last-child {
  flex: 1 5 auto;
}

#mango_checkout_redesign {
  margin: 7vh 0 0 0 !important;
  padding: 5vh 0;
  background-color: #E0D3CB;
}

#mango_checkout_redesign > main > div > h2 {
  margin-top: 0;
}

#mango_checkout_redesign > main > div > div > img {
  max-width: 75vw;
}

.mango_desktop_mobile > img:first-child {
  flex: 13 4 0;
}

.mango_desktop_mobile > img:last-child {
  flex: 4 13 0;
}

/* Captured */

section.captured_style_guide {
  width: 100%;
  max-width: 2000px;
}

section.captured_style_guide p {
  font-family: 'Roboto', sans-serif;
  color: var(--captured_primary_font_color);
}

.captured_style_guide_typography {
  margin: 5vh auto;
}

.captured_style_guide_typography > div {
  display: inline-block;
}

.captured_style_guide_typography > div > p {
  font-weight: 700;
}

.captured_style_guide_typography > div:first-of-type > p {
  font-size: 350px;
  margin: 0 100px;
}

.captured_style_guide_typography > div:last-of-type > p {
  max-width: 700px;
  text-align: left;
  margin-left: 0;
}

.captured_style_guide_typography > div:last-of-type > p:first-of-type {
  font-size: 64px;
}

.captured_style_guide_typography > div:last-of-type > p:last-of-type {
  font-size: 24px;
}

.captured_style_guide_colors > div > h2 {
  margin: 3vh auto 24px auto;
}

.captured_style_color {
  display: inline-block;
  margin: 8px 16px;
}

.captured_style_color > div:first-child {
  height: 200px;
  width: 200px;
  border-radius: 32px;
  margin: 0;
}

.captured_style_color_text {
  font-weight: 700;
  text-align: right;
}

.captured_style_color_text > p {
  margin: 8px 0;
}

.captured_style_color_text > p:last-of-type {
  color: var(--captured_secondary_font_color);
}

#captured_image_overview_devices {
  max-width: 90%;
}

#captured_image_overview_devices > img:first-child {
  max-width: 75%;
}

#captured_search_images > img {
  margin: auto;
}

#captured_upload_images > img:last-child {
  max-width: 75vw;
}

/* Other */

.project_cover {
  margin: calc(min(180px, 16vh)) auto;
}

.project_cover > p {
  margin: 10px auto;
}

article > header {
  margin-bottom: 1rem;
}

article > a > img {
  margin-top: 3vh;
  margin-bottom: 0;
}

div.project_link > a > h3 {
  display: inline-block;
}

.project_cover > a:hover + div.project_link > a > h3 {
  color: var(--accent_color);
}

div.project_link > a:hover > svg,
.project_cover > a:hover + div.project_link > a > svg {
  fill: var(--accent_color);
}

footer {
  margin: 18vh auto 4vh auto;
}

footer > h2 {
  margin-bottom: 5vh;
}

footer > p {
  margin-top: 5vh;
}

#footer_email {
  color: var(--accent_color);
  text-decoration: underline;
  display: inline-block;
}

.view_case_study_arrow {
  position: relative;
  left: 0.5rem;
  top: 0.1rem;
}

button {
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  color: var(--primary_font_color);
  font-size: 18px;
}

#projects_carousel {
  display: flex;
  justify-content: space-between;
}

#projects_carousel > a {
  font-size: 1.3rem;
  color: var(--accent_color);
}

#projects_carousel > a > svg {
  margin: 0 0.5rem 0.2rem 0.5rem;
  transition: 0.15s linear;
  transform: scale(1.8);
  vertical-align: middle;
}

#projects_carousel > a:hover > svg {
  transform: scale(2.5);
}

#previous_project_link:hover > svg {
  margin-left: 0;
  margin-right: 1rem;
}

#next_project_link:hover > svg {
  margin-right: 0;
  margin-left: 1rem;
}

@media screen and (max-width: 1600px) {

  /* PetNanny */

  #pet_nanny_design_steps {
    flex-wrap: wrap;
  }

  #pet_nanny_design_steps > div {
    flex: 0 0 25%;
  }

  #pet_nanny_design_steps > div > img {
    width: 130px;
    height: 130px;
  }

  #pet_nanny_design_steps > div:nth-child(even) {
    margin-top: 0;
  }

  #pet_nanny_factors {
    flex-direction: column;
    max-width: 700px;
  }

  #pet_nanny_competitors > div {
    max-width: 90%;
  }

  /* Mango */

  #colors_and_typography_libre_franklin > div > p {
    font-size: 12px;
  }

  .color_purse > p {
    bottom: calc(160px + 0.5vw);
  }
}

/* tablet */
@media (max-width: 900px) {

  /* main */

  main {
    margin: auto 5%;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.9rem;
  }

  q {
    font-size: 1rem;
  }

  li {
    font-size: 0.9rem;
  }

  .border_radius_medium {
    border-radius: var(--min_v3);
  }

  .border_radius_large {
    border-radius: var(--min_v4);
  }

  #header_menu {
    height: 63px;
  }

  #header_menu > nav {
    width: 88%;
    padding: 10px 0;
  }

  #toggle_menu {
    display: inline-block;
  }

  #header_menu_links {
    position: fixed;
    top: 20vh;
    left: -100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4vh;
    transition: left 200ms 50ms;
  }

  #header_menu_links > a {
    font-size: 40px;
    color: white;
  }

  #header_menu_links.links_visible {
    left: 5vw;
  }

  #hi {
    margin-top: 15vh;
  }

  .circle_jump_button {
    margin-top: 5vh;
    transform: scale(0.75);
  }

  #projects_carousel > a {
    font-size: 0.9rem;
  }

  .resp_01:has(> :nth-child(3):last-child) {
    gap: var(--max_v5);
  }

  .resp_01:has(> :nth-child(4):last-child) {
    gap: var(--max_v5);
  }

  .resp_01 {
    flex-direction: column;
    gap: var(--max_v5);
  }

  .resp_01 img,
  .resp_01 video {
    margin: 0 auto;
  }

  .img_mw75 img {
    max-width: 75vw;
  }

  /* footer */

  #footer_circle_jump_button {
    transform: scale(0.4);
    bottom: -12px;
    right: -12px;
  }

  #footer_circle_jump_button:hover {
    opacity: 0.4;
  }

  /* art gallery */

  #art_gallery_artworks > img {
    flex: unset;
  }

  #art_gallery_artworks > div {
    flex: unset;
  }

  #art_gallery_artworks > div > img {
    margin-top: 0;
  }

  #art_gallery_artworks > div > img + img {
    margin-top: var(--max_v5);
  }

  #art_gallery_artworks > div:nth-child(3) > img:first-child {
    margin-top: 0;
  }

  #art_gallery_takeaways > div {
    gap: var(--max_v5);
  }

  .art_gallery_padding {
    padding: var(--max_v5);
  }

  #art_gallery_fade > div > img:last-child {
    height: calc(100% - 2 * var(--max_v5));
  }

  /* NoteX */

  #julia > img {
    margin: 0 auto;
    max-height: 35vh;
  }

  /* PetNanny */

  #pet_nanny_design_steps {
    flex-direction: row;
  }

  #pet_nanny_design_steps > div > img {
    width: 80px;
    height: 80px;
  }

  #pet_nanny_interface {
    flex-direction: column;
    row-gap: 5vh;
  }

  #pet_nanny_quick_and_easy_booking img {
    max-width: 80%;
  }

  #pet_nanny_factors {
    gap: 1vh;
  }

  #pet_nanny_factors > div > h2 {
    margin-top: 1.8rem;
  }

  #pet_nanny_factors > div > svg {
    transform: scale(2.5);
  }

  #pet_nanny_interface > img {
    width: 100%;
  }

  #pet_nanny_usability > div {
    display: flex;
    flex-direction: column-reverse;
  }

  #pet_nanny_usability img {
    margin: 3vh auto;
  }

  #pet_nanny_usability h2 {
    margin: 0 auto;
  }

  #pet_nanny_safe_hands {
    gap: 5vh !important;
  }

  /* Mango */

  #mango_responsiveness > div {
    display: flex;
    flex-direction: column-reverse;
  }

  #mango_responsiveness > div > div > p {
    margin: 3vh 0;
  }

  #mango_responsiveness > div > div > h2 {
    margin: 0 auto;
  }

  /* Captured */

  .captured_style_guide_typography > div {
    display: block;
  }

  .captured_style_guide_typography > div > p {
    margin: 1.2vh auto !important;
  }

  .captured_style_guide_typography > div:first-of-type > p {
    font-size: 175px;
    margin: 0;
  }

  .captured_style_guide_typography > div:last-of-type > p {
    max-width: 500px;
  }

  .captured_style_guide_typography > div:last-of-type > p:first-of-type {
    font-size: 36px;
  }

  .captured_style_guide_typography > div:last-of-type > p:last-of-type {
    font-size: 20px;
  }

  .captured_style_color {
    display: block;
  }

  .captured_style_color > div {
    display: inline-block;
  }

  .captured_style_color_text {
    width: 185px;
    vertical-align: top;
    text-align: left;
    margin-top: 6px;
    margin-left: 12px;
  }

  .captured_style_color_text > p {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .captured_style_color > div:first-child {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }
}
